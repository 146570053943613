<template>
  <div v-if="packages !== null">
    <b-container class="bv-section1-row">
      <b-row class="px-3 px-lg-0">
        <h3 v-if="localeTitle == 'en'">
          {{ area.enTitle }}
        </h3>
        <h3 v-if="localeTitle == 'cn'">
          {{ area.cnTitle }}
        </h3>
        <h3 v-if="localeTitle == 'hk'">
          {{ area.hkTitle }}
        </h3>
      </b-row>
      <b-row v-if="area.featuredVideoUrl">
        <video
          width="1140"
          height="640px"
          controls
          :autoplay="area.autoplay==='Y'?true:false"
          :muted="area.autoplay==='Y'?true:false"
          :loop="area.loopFlag==='Y'?true:false"
          class="w-100 px-3 px-lg-0"
          v-if="area.featuredVideoUrl"
        >
          <source :src="oss+area.featuredVideoUrl" />
          {{ $t('header.browser_msg') }}
        </video>
      </b-row>
    </b-container>
    <section
      class="secction-h tour-section-2 tour-section-4 mt-5 pt-5 pb-5"
      v-if="packages.length > 0"
    >
      <carousel-3d
        :controls-visible="true"
        :controls-prev-html="'&#10092; '"
        :controls-next-html="'&#10093;'"
        :controls-width="30"
        :controls-height="60"
        :clickable="true"
        :width="405"
        :height="450"
      >
        <slide :index="0" v-if="packages[0]">
          <router-link :to="`/tour/${packages[0].packageCode}`">
            <div
              :style="{
                backgroundImage: `url(&quot;${oss+packages[0].mainPicture}&quot;)`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '405px 450px',
                height: '100%',
              }"
              class="d-flex flex-column-reverse"
            >
              <h5 class="bp-title mx-2">
                <v-clamp autoresize :max-lines="3">
                  ({{ packages[0].shortDescription }})</v-clamp
                >
              </h5>
              <p class="bp-title text-center">{{ packages[0].title }}</p>
            </div>
          </router-link>
        </slide>
        <slide :index="1" v-if="packages[1]">
          <router-link :to="`/tour/${packages[1].packageCode}`">
            <div
              :style="{
                backgroundImage: `url(&quot;${oss+packages[1].mainPicture}&quot;)`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '405px 450px',
                height: '100%',
              }"
              class="d-flex flex-column-reverse"
            >
              <h5 class="bp-title mx-2">
                <v-clamp autoresize :max-lines="3">
                  ({{ packages[1].shortDescription }})</v-clamp
                >
              </h5>
              <p class="bp-title text-center">{{ packages[1].title }}</p>
            </div>
          </router-link>
        </slide>
        <slide :index="2" v-if="packages[2]">
          <router-link :to="`/tour/${packages[2].packageCode}`">
            <div
              :style="{
                backgroundImage: `url(&quot;${oss+packages[2].mainPicture}&quot;)`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '405px 450px',
                height: '100%',
              }"
              class="d-flex flex-column-reverse"
            >
              <h5 class="bp-title mx-2">
                <v-clamp autoresize :max-lines="3">
                  ({{ packages[2].shortDescription }})</v-clamp
                >
              </h5>
              <p class="bp-title text-center">{{ packages[2].title }}</p>
            </div>
          </router-link>
        </slide>
        <slide :index="3" v-if="packages[3]">
          <router-link :to="`/tour/${packages[3].packageCode}`">
            <div
              :style="{
                backgroundImage: `url(&quot;${oss+packages[3].mainPicture}&quot;)`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '405px 450px',
                height: '100%',
              }"
              class="d-flex flex-column-reverse"
            >
              <h5 class="bp-title mx-2">
                <v-clamp autoresize :max-lines="3">
                  ({{ packages[3].shortDescription }})</v-clamp
                >
              </h5>
              <p class="bp-title text-center">{{ packages[3].title }}</p>
            </div>
          </router-link>
        </slide>
        <slide :index="4" v-if="packages[4]">
          <router-link :to="`/tour/${packages[4].packageCode}`">
            <div
              :style="{
                backgroundImage: `url(&quot;${oss+packages[4].mainPicture}&quot;)`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '405px 450px',
                height: '100%',
              }"
              class="d-flex flex-column-reverse"
            >
              <h5
                class="bp-title mx-2"
                v-shave="{
                  height: 150,
                  character: '...',
                  spaces: false,
                }"
              >
                <v-clamp autoresize :max-lines="3">
                  ({{ packages[4].shortDescription }})</v-clamp
                >
              </h5>
              <p class="bp-title text-center">{{ packages[4].title }}</p>
            </div>
          </router-link>
        </slide>
      </carousel-3d>
      <b-container class="bv-section4-row">
        <div class="text-center mt-2" v-if="packages.length > 5">
          <a :href="`#/packages/section4`"
            ><b-button class="view-more" squared variant="outline-secondary">{{
              $t('More')
            }}</b-button></a
          >
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
import VClamp from 'vue-clamp';
import configUrl from '@/global/config.js'
export default {
  name: 'Section4',
  components: {
    Carousel3d,
    Slide,
    VClamp,
  },
  data() {
    return {
      loading: true,
      errored: false,
      localeTitle: 'en',
      oss:''
    };
  },
  props: {
    area: {
      type: Array,
    },
  },
  computed:{
    packages(){
      return this.area&&this.area.packagesVos||[]
    }
  },
  mounted: function () {
    this.oss=configUrl.oss_path;
    if (localStorage.getItem('lang_frontend') == 'en') {
      this.localeTitle = 'en';
    } else if (localStorage.getItem('lang_frontend') == 'cn') {
      this.localeTitle = 'cn';
    } else {
      this.localeTitle = 'hk';
    }
  },
};
</script>

<style>
.prev[data-v-05517ad0],
.next[data-v-05517ad0] {
  color: white !important;
}

.demo {
  width: 1140px;
  height: 430px;
  margin: 0 auto;
}
.bv-section4-row {
  padding-left: 0;
  padding-right: 0;
}
.row.area-title {
  position: absolute;
  top: -6.5rem;
}

.tour-section-4 {
  background-color: #0e2b33;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.bp-title {
  color: white;
  text-shadow: -1px -1px 0 #ff7e09, 1px -1px 0 #ff7e09, -1px 1px 0 #ff7e09,
    1px 1px 0 #ff7e09;
}
</style>
